<template>
  <ik-data-table
    :entity-name="$t('openTime')"
    :icon="'mdi-calendar-clock'"
    :model="model"
  />
</template>

<script>

  import OpeningTime from '../../../../model/takeaway/OpeningTime'
  import { IkDataTable } from 'metaflow-js'

  export default {
    components: { IkDataTable },
    data: function () {
      return ({
        model: OpeningTime,
      })
    },
  }

</script>
